<template>
  <b-modal
    id="modal-school"
    ref="refModal"
    title="Cơ sở"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group label="Tên cơ sở">
                <template v-slot:label>
                  Tên cơ sở
                  <span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="address"
              rules="required"
            >
              <b-form-group label="Địa chỉ">
                <template v-slot:label>
                  Địa chỉ
                  <span class="text-danger"> (*) </span>
                </template>
                <b-form-input
                  id="address"
                  v-model="itemLocal.address"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="tingee"
              rules=""
            >
              <select-tingee-account
                v-model="itemLocal.tingee"
                label="Chọn số tài khoản"
                :default-value="itemLocal.tingee"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :ignore-cash="true"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="template"
              rules=""
            >
              <select-template
                v-model="itemLocal.template"
                label="Mẫu in hóa đơn"
                :default-value="itemLocal.template"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SelectTingeeAccount from "@/views/components/SelectTingeeAccount.vue";
import SelectTemplate from "@/views/components/SelectTemplate.vue";
import useSchoolModal from "./useSchoolModal";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SelectTemplate,
    SelectTingeeAccount,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useSchoolModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
